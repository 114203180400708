<template>
  <div class="home">
    <Sched />
  </div>
</template>

<script>
// @ is an alias to /src
import Sched from '@/components/Sched.vue'

export default {
  name: 'home',
  components: {
    Sched
  }
}
</script>
