<template>
  <v-container>
    <v-layout text-center wrap>
      <div class="mt-5 row">
        <v-card class="mr-4 d-inline xs3" style="width: 25%;">
          <v-card-title>
            <span class="headline">Audit Review Scheduler</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div>
              <p class="title">Physician Info</p>
              <p class="body-2 text-left">
                <strong>Name:</strong> {{ $root.provider.FirstName }}
                {{ $root.provider.LastName }},
                {{ $root.provider.DesignationString }}
              </p>
              <p class="body-2 text-left">
                <strong>Position:</strong> {{ $root.provider.JobProfile }}
              </p>
              <p class="body-2 text-left">
                <strong>Email:</strong> {{ $root.provider.EmailAddress }}
              </p>
              <p class="body-2 text-left">
                <strong>Audit Date:</strong> {{ $root.provider.LastAuditDate }}
              </p>
              <v-select
                :items="tzones"
                label="Timezone"
                v-model="tz"
              ></v-select>
            </div>
            <div v-if="selected">
              <v-divider></v-divider>
              <p class="title mt-4">Selected Event</p>
              <p class="body-2 text-left">
                <strong>Start:</strong>
                {{ slot.start | moment("ddd, MMM Do YYYY - h:mm:ss a") }}
              </p>
              <p class="body-2 text-left">
                <strong>End:</strong>
                {{ slot.end | moment("ddd, MMM Do YYYY - h:mm:ss a") }}
              </p>
            </div>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions class="d-flex flex-row-reverse">
            <v-btn :loading="bload" class="success float-right" @click="putSlot"
              >Book Slot
              <v-icon v-if="updated" dark right
                >mdi-checkbox-marked-circle</v-icon
              >
            </v-btn>
          </v-card-actions>
          <v-alert class="ma-3" outlined dense :type="alert">
            {{ alerttext }}
          </v-alert>
        </v-card>

        <vue-cal
          class="d-inline xs9 elevation-2"
          :time-from="9 * 60"
          :time-to="18 * 60"
          :time-step="30"
          hide-view-selector
          hide-weekends
          :disable-views="['years', 'year', 'month', 'day']"
          @cell-click="selectSlot($event)"
          @view-change="viewChange($event)"
          :events="events"
        ></vue-cal>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import axios from "axios";

export default {
  name: "Sched",
  components: {
    VueCal,
  },
  data: () => ({
    alert: "info",
    alerttext:
      "Educational session has not been scheduled yet. Once selected, please press 'Book Now' button ONCE.",
    events: [],
    selected: false,
    bload: false,
    slot: {},
    updated: false,
    ws: "",
    we: "",
    tz: "Eastern Standard Time",
    tzones: [
      "Pacific Standard Time",
      "Mountain Standard Time",
      "Central Standard Time",
      "Eastern Standard Time",
    ],
  }),
  beforeMount: function() {
    if (this.$root.provider) {
      if (
        this.$root.provider.ScheduledEd &&
        this.$root.provider.ScheduledEd.start
      ) {
        this.slot = this.$root.provider.ScheduledEd;
        this.selected = true;
      }
    } else {
      this.$router.push({ name: "auth" });
    }
  },
  mounted: function() {
    this.ws = this.getDayDate("Mon", true);
    this.we = this.getDayDate("Fri", false);
    this.getSlots(this.ws, this.we);
  },
  watch: {
    tz: function(t) {
      console.log(t);
      this.getSlots(this.ws, this.we);
    },
  },
  methods: {
    logEvents(e, ev) {
      console.log(e + ": " + ev);
    },
    selectSlot(d) {
      console.log(d);
      let now = new Date();
      if (d && d > now) {
        let slot = { title: "CHOSEN SLOT", class: "chosen" },
          date = d.toISOString().split("T")[0],
          h = d.getHours(),
          m = d.getMinutes(),
          hour = h.toString().length == 1 ? "0" + h : h,
          eh = h + 1,
          ehour = eh.toString().length == 1 ? "0" + eh : eh;
        if (m < 30) {
          slot.start = date + " " + hour + ":00";
          slot.end = date + " " + hour + ":30";
        } else {
          slot.start = date + " " + hour + ":30";
          slot.end = date + " " + ehour + ":00";
        }

        console.log(JSON.stringify(slot));

        let events = this.events,
          elen = this.selected ? events.length - 1 : events.length,
          blocked = false;
        for (let e = 0; e < elen; e++) {
          // if(events[e].start < slot.start && slot.start < events[e].end){
          //   blocked = true;
          //   break;
          // }else
          if (events[e].start < slot.end && slot.end <= events[e].end) {
            blocked = true;
            break;
          }
        }
        if (!blocked) {
          if (this.selected) {
            this.events.pop();
          }
          this.events.push(slot);
          this.slot = slot;
          this.selected = true;
          this.updated = false;
        }
      }
    },
    viewChange(ev) {
      let start = ev.startDate.toISOString().split("T")[0] + "T00:00:00.000Z",
        end = ev.endDate.toISOString().split("T")[0] + "T23:59:59.000Z";
      this.ws = start;
      this.we = end;
      this.getSlots(start, end);
    },
    getSlots(s, e) {
      axios
        .get(
          "https://wh94d7cw29.execute-api.us-east-1.amazonaws.com/prod/getavailableslots?tz=" +
            this.tz +
            "&st=" +
            s +
            "&et=" +
            e
        )
        .then((res) => {
          console.log(res.data.length);
          if (res.data.length > 0) {
            //console.log("DATA RETRIEVED: " + JSON.stringify(res));
            let data = res.data;
            this.events = data;
            if (this.selected) {
              this.events.push(this.slot);
            }
          } else {
            console.log("SOMETHING WENT WRONG");
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // putSlot(){
    //   axios.post('https://wh94d7cw29.execute-api.us-east-1.amazonaws.com/prod/putedslot',
    //               {"slot":this.slot,"EmailAddress":this.$root.provider.EmailAddress,"Client":this.$root.provider.Client}
    //   ).then(res => {
    //     console.log(res.data);
    //     if(res.data.response == "success"){
    //       this.updated = true;
    //     }else{
    //       this.updated = false;
    //     }
    //   }).catch(function (error) {
    //     console.log(error);
    //   })
    // },
    putSlot() {
      this.alert = "warning";
      this.alerttext = "Education slot sending. Please wait...";
      this.bload = true;
      axios
        .post(
          "https://wh94d7cw29.execute-api.us-east-1.amazonaws.com/prod/bookslot",
          {
            slot: this.slot,
            Email: this.$root.provider.EmailAddress,
            Client: this.$root.provider.Client,
            FirstName: this.$root.provider.FirstName,
            LastName: this.$root.provider.LastName,
            DesignationString: this.$root.provider.DesignationString,
            SkyprepID: this.$root.provider.SkyprepID,
            tz: this.tz,
          }
        )
        .then((res) => {
          console.log(res.data);
          this.bload = false;
          this.alert = "success";
          this.alerttext =
            "Request submitted. Please do not click Book Now button. You are free to close this window now. ";
          if (res.data.response == "success") {
            this.updated = true;
          } else {
            this.updated = false;
          }
        })
        .catch(function(error) {
          this.alert = "error";
          this.alerttext =
            "Request did not go through. Please attempt to submit request again. If the system does not allow you to send the request, please contact education@edelberg.com for further instructions. ";
          console.log(error);
        });
    },
    getDayDate(dstr, st) {
      let off = { Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5 },
        d = new Date(),
        day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : off[dstr]),
        pred = new Date(d.setDate(diff)).toISOString().split("T")[0];
      return pred + "T" + (st ? "00:00:00.000Z" : "23:59:59.000Z");
    },
  },
};
</script>

<style>
.vuecal__event.booked {
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    #e9a4a4 10px,
    #e9a4a4 20px
  ); /* IE 10+ */
  color: darkslategrey;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vuecal__event.booked .vuecal__event-time {
  display: none;
  align-items: center;
}

.container {
  max-width: 95% !important;
}

.vuecal__event.chosen {
  background: green;
  color: white;
  /* display: flex; */
  padding-top: 6px;
  line-height: 14px;
  justify-content: center;
  align-items: center;
}
</style>
